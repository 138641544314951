import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import About from './screen/About';
import Home from './screen/Home';
import Contact from './screen/Contact';
import Project from './screen/Project';
import { Navigation } from './components/ui/Header';
import { Footer } from './components/ui/Footer';
import { AnimatePresence } from 'framer-motion';
import Porfolio_Cave_Sonneur from './screen/portfolio/CaveSonneur'
import Porfolio_Erwan_Musique from './screen/portfolio/ErwanMusique';
import { useEffect } from 'react';
import { ScrollToTop } from './components/ui/Animations'

//Quand on clique sur "Services" ou "Projets" dans l'en-tête un offset est ajouté afin d'arriver un peu plus haut que la classe. 
//C'est une question d'estétisme
const ScrollToHashWithOffset = ({ offset }) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // Ajoutez un délai pour s'assurer que le DOM est rendu avant de défiler
      setTimeout(() => {
        const element = document.getElementById(hash.replace("#", ""));
        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
      }, 100);
    }
  }, [hash, offset]);

  return null;
};

const AnimatedRoutes = () => {
  const location = useLocation();
  return(
    <AnimatePresence >
    <Routes location={location} key={location.pathname}>
      <Route exact path="/" element={<Home />} />
      <Route path="/a-propos" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/projets" element={<Project />} />
      <Route path="/portfolio/cave-du-sonneur" element={<Porfolio_Cave_Sonneur />} />
      <Route path="/portfolio/erwan-musique" element={<Porfolio_Erwan_Musique />} />
    </Routes>
  </AnimatePresence>
  )

}

function App() {

  return (
   
    <Router>
      <ScrollToTop/>
        <ScrollToHashWithOffset offset={70} /> {/* Passez votre offset ici */}
      <Navigation />
      <AnimatedRoutes/>
      <Footer/>
    </Router>
   

  );
}

export default App;
